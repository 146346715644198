
import { mapGetters, mapState } from "vuex";
import MenuBlock from "./MenuBlock.vue";
import LanguageSwitcher from "@/components/common/LanguageSwitcher";
import { topMenuFormatter } from "@/utils";
export default {
  components: {
    MenuBlock,
    LanguageSwitcher,
  },
  data() {
    return {
      headerMenu: [],
      mocMenuData: [
        {
          url: "/",
          label: "Home",
          isOpen: false,
          columns: [{ list: [{ label: "Blog", url: "/articles" }] }],
        },
        {
          url: "/shop",
          label: "Shop",
          isOpen: false,
          columns: [
            {
              title: "Jewelry",
              list: [
                { label: "All", url: "/catalog" },
                { label: "Rings", url: "/catalog/ring", isHot: true },
                { label: "Bracelets", url: "/catalog/bracelets", isHot: true },
              ],
            },
            {
              title: "Collections",
              list: [
                { label: "All", url: "/collections" },
                { label: "Initial", url: "/collection/initial", isNew: true },
              ],
            },
          ],
        },
        {
          url: "/best-sellers",
          label: "Best Sellers",
          isOpen: false,
          columns: [],
        },
        {
          url: "/sale",
          label: "Sale",
          isOpen: false,
          columns: [],
        },
        {
          url: "/about",
          label: "About",
          isOpen: false,
          columns: [],
        },
        {
          url: "/resources",
          label: "Resources",
          isOpen: false,
          columns: [{ list: [{ label: "FAQ", url: "/faq" }] }],
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getLanguages: "general/getLanguages",
    }),
    ...mapState({
      isOpenSidebar: (state) => state.general.isOpenSideMenu,
    }),
  },
  created() {
    if (this.$store.state.general?.menu?.header?.items) {
      this.headerMenu = topMenuFormatter(
        this.$store.state.general.menu.header.items
      );
    }
  },
  methods: {
    toggleMenu() {
      this.$store.commit("general/TOGGLE_SIDE_MENU");
    },
  },
};
