
import { mapMutations, mapState } from "vuex";
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    menuType: {
      type: String,
      default: "top",
    },
  },
  data() {
    return {
      activeItemImage: null,
    };
  },
  computed: {
    ...mapState({
      activeMenu: (state) => state.general.desktopMenu,
    }),
  },
  mounted() {
    window.addEventListener("scroll", () => {
      this.setDesktopMenu(null);
    });
  },
  methods: {
    ...mapMutations({
      setDesktopMenu: "general/SET_DESKTOP_MENU",
    }),
    onMouseOver(e, identification, idx) {
      if (this.menuType === "top") {
        const el = e.target.closest("li");
        const elMenu = el.querySelector(`.nav__mega-menu`);

        if (elMenu) {
          const elCoord = el.getBoundingClientRect();
          const elMenuCoord = elMenu.getBoundingClientRect();

          const liCenter = (elCoord.right - elCoord.left) / 2 + elCoord.left;
          elMenu.style.transition = "none";
          elMenu.style.position = "fixed";
          let left = liCenter - elMenuCoord.width / 2;
          left = left < 0 ? 0 : left;

          elMenu.style.top = elCoord.bottom + 16 + "px";
          if (elMenuCoord.width > window.innerWidth) {
            elMenu.style.width = window.innerWidth;
          }
          if (left + elMenuCoord.width > window.innerWidth) {
            elMenu.style.left = `0px`;
          } else {
            elMenu.style.left = `${left}px`;
          }

          elMenu.style.transition = "all 0.3s";
        }
      }
      this.setDesktopMenu(identification);
    },
    onMouseLeave() {
      this.setDesktopMenu(null);
    },
    onMouseOverItem(item) {
      this.activeItemImage = item?.image || null;
    },
  },
};
